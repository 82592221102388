import React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

/**
 * @param {Object} props
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 * @param {"left" | "right" | "center"} [props.align]
 * @param {Object} [props.containerProps]
 */
export default function ButtonGroup({
  className,
  children,
  align,
  ...containerProps
}) {
  return (
    <div
      className={cn(
        align === "right" && styles.buttonGroup_alignRight,
        styles.buttonGroup,
        className,
      )}
      {...containerProps}
    >
      {children}
    </div>
  );
}

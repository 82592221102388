import { cn } from "@sys42/utils";

import { ExternalLink } from "@/design-system";
import {
  addUtmParams,
  urlBookADemo,
  urlContact,
  urlPricing,
} from "@/helpers-ts";
import { ToggleBillingCycle } from "../ToggleBillingCycle";

import styles from "./styles.module.css";

import imageBulletCancelAnytime from "./bulletFreeTrial.png";
import imageBulletLifebuoy from "./bulletLifebuoy.png";
import imageBulletOk from "./bulletOk.png";

export function UserbrainPlans(props) {
  const {
    className,
    children,
    title,
    billingCycle,
    infoBox,
    onChangeBillingCycle,
  } = props;

  return (
    <div className={cn(className, styles.userbrainPlans)}>
      <h1 className={styles.title}>{title}</h1>
      <ToggleBillingCycle
        className={styles.toggleBillingCycle}
        onChange={onChangeBillingCycle}
        value={billingCycle}
      />
      {infoBox && <div className={styles.infoBox}>{infoBox}</div>}
      <div className={styles.plans}>{children}</div>
      <div className={styles.bullets}>
        <Bullet
          imgSrc={imageBulletOk}
          imgAlt="Line drawing of a hand making an OK sign"
          title="100% satisfaction guarantee"
          description="If you’re not happy with one of our testers, you’ll get a new one at no extra cost."
        />
        <Bullet
          imgSrc={imageBulletCancelAnytime}
          imgAlt="Line drawing of a mouse pointer clicking"
          title="Need more testers?"
          description={
            "Upgrade your plan anytime or simply buy more testers on demand."
          }
        />
        <Bullet
          imgSrc={imageBulletLifebuoy}
          imgAlt="Line drawing of a lifebuoy"
          title="Still got questions?"
          description={
            <>
              You can{" "}
              <a
                className={styles.fancyLink}
                href={urlContact}
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </a>{" "}
              or{" "}
              <a
                className={styles.fancyLink}
                href={addUtmParams(urlBookADemo, "dashboard", "pricing")}
                target="_blank"
                rel="noreferrer"
              >
                book a demo
              </a>
              <br />
              &nbsp;
            </>
          }
        />
      </div>
      <div className={styles.viewAllFeatures}>
        See all features and more{" "}
        <ExternalLink className={styles.viewAllFeaturesLink} href={urlPricing}>
          Visit our pricing page
        </ExternalLink>
      </div>
    </div>
  );
}

function Bullet({ imgSrc, imgAlt, title, description }) {
  return (
    <div className={styles.bulletsItem}>
      <img src={imgSrc} alt={imgAlt} />
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

import { createContext, ReactNode, useContext } from "react";
import { cn } from "@sys42/utils";

import ButtonGroup from "@/components/ButtonGroup";
import { PaymentForm, PaymentFormProps } from "@/components/PaymentForm";
import {
  ButtonPrimary,
  Checkbox,
  ErrorMessage,
  FormField,
} from "@/design-system";
import { formatPrice, pluralizer } from "@/helpers";
import { urlPrivacy, urlTerms } from "@/helpers-ts";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClose } from "./icons/icon-close.svg";

type Payment = {
  price: number;
  vat: number;
};

export type Subscription = Payment;

export type Credits = {
  amount: number;
} & Payment;

type CheckoutModalProps = {
  isLoading: boolean;
  onClickClose: () => void;
  errorMessage: string | null;
  isConfirmButtonDisabled: boolean;
  confirmButtonLabel: string;
  onClickConfirm: () => void;
  title: string;
  paymentDescription?: ReactNode;
  paymentType: "credits" | "existingSubscription" | "newSubscription";
} & CheckoutModalContextProps &
  Omit<PaymentFormProps, "className">;

type CheckoutModalContextProps = {
  currency: string;
  // CREDITS
  credits: Credits;
  creditsWordingPluralizer?: (arg1: number, arg2: boolean) => string;
  // SUBSCRIPTION
  subscriptionName: string;
  activeSubscriptionCycle: BillingCycle;
  monthlySubscription: Subscription;
  annualSubscription: Subscription;
  onClickSubscriptionCycle: (cycle: BillingCycle) => void;
  // LEGAL
  isLegalAgreementRequired: boolean;
  isDisplayLegalError: boolean;
  isLegalAgree: boolean;
  onClickLegalAgree: () => void;
};

const CheckoutModalContext = createContext({} as CheckoutModalContextProps);

const SUBSCRIPTION_CYCLE_COPY = {
  annual: {
    adjective: "Yearly",
    noun: "year",
  },
  monthly: {
    adjective: "Monthly",
    noun: "month",
  },
};

export function CheckoutModalRedesign({
  isLoading,
  onClickClose,
  errorMessage,
  isConfirmButtonDisabled,
  confirmButtonLabel,
  onClickConfirm,
  card,
  title,
  isChangeCardAllowed,
  onClickChangeCard,
  onChangeIsNewCardValid,
  paymentDescription,
  paymentType,
  ...contextProps
}: CheckoutModalProps) {
  return (
    <CheckoutModalContext.Provider value={contextProps}>
      <div
        className={cn(styles.container, isLoading && styles.container_loading)}
      >
        <h2 className={styles.heading}>{title}</h2>
        <button onClick={onClickClose} className={styles.closeButton}>
          <SvgIconClose />
        </button>
        {errorMessage && (
          <ErrorMessage
            className={styles.errorMessage}
            message={errorMessage}
          />
        )}
        {paymentType === "newSubscription" && <NewSubscriptionPayment />}
        {paymentType === "existingSubscription" && (
          <ExistingSubscriptionPayment />
        )}
        {paymentType === "credits" && <CreditsPayment />}
        {paymentDescription && (
          <p className={styles.paymentDescription}>{paymentDescription}</p>
        )}
        <PaymentForm
          className={styles.paymentForm}
          card={card}
          isChangeCardAllowed={isChangeCardAllowed}
          onClickChangeCard={onClickChangeCard}
          onChangeIsNewCardValid={onChangeIsNewCardValid}
        />
        <Legal />
        <ButtonGroup className={styles.footer} align={"right"}>
          <ButtonPrimary
            disabled={isConfirmButtonDisabled}
            onClick={onClickConfirm}
          >
            {confirmButtonLabel}
          </ButtonPrimary>
        </ButtonGroup>
      </div>
    </CheckoutModalContext.Provider>
  );
}

function Legal() {
  const {
    isDisplayLegalError,
    isLegalAgree,
    isLegalAgreementRequired,
    onClickLegalAgree,
  } = useContext(CheckoutModalContext);

  const termsAndConditionsLinkElement = (
    <a
      href={urlTerms}
      title="Terms and Conditions"
      target="_blank"
      rel="noreferrer"
    >
      Terms&nbsp;&&nbsp;Conditions
    </a>
  );

  const privacyPolicyLinkElement = (
    <a
      href={urlPrivacy}
      title="Privacy Policy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy&nbsp;Policy
    </a>
  );

  const newAgreementElement = (
    <FormField
      errorMessage={
        isDisplayLegalError
          ? "You need to accept our terms to complete your purchase."
          : undefined
      }
      className={styles.legalCheckboxFormField}
      errorClassName={styles.legalFormFieldError}
    >
      <Checkbox
        checked={isLegalAgree}
        onChange={onClickLegalAgree}
        className={styles.legalCheckbox}
        isError={isDisplayLegalError}
      >
        I agree to the {termsAndConditionsLinkElement} and{" "}
        {privacyPolicyLinkElement}.
      </Checkbox>
    </FormField>
  );

  const existingAgreementElement = (
    <p className={styles.legalExistingAgreement}>
      By placing your order, you agree to the {termsAndConditionsLinkElement}{" "}
      and {privacyPolicyLinkElement}.
    </p>
  );

  return isLegalAgreementRequired
    ? newAgreementElement
    : existingAgreementElement;
}

function NewSubscriptionPayment() {
  const { activeSubscriptionCycle } = useContext(CheckoutModalContext);

  return (
    <>
      <div className={styles.subscriptionPayment}>
        <NewSubscriptionPaymentButton
          cycle="monthly"
          isActive={activeSubscriptionCycle === "monthly"}
        />
        <NewSubscriptionPaymentButton
          cycle="annual"
          isActive={activeSubscriptionCycle === "annual"}
        />
      </div>
    </>
  );
}

function NewSubscriptionPaymentButton({
  cycle,
  isActive,
}: {
  cycle: BillingCycle;
  isActive: boolean;
}) {
  const {
    monthlySubscription,
    annualSubscription,
    currency,
    onClickSubscriptionCycle,
  } = useContext(CheckoutModalContext);
  const { price, vat } =
    cycle === "monthly" ? monthlySubscription : annualSubscription;

  const handleClick = () => {
    onClickSubscriptionCycle(cycle);
  };

  const priceVatInfo = vat > 0 && (
    <>
      {formatPrice(price)} + {formatPrice(vat)}&nbsp;VAT
    </>
  );

  return (
    <button
      onClick={handleClick}
      className={cn(
        styles.subscriptionPaymentButton,
        isActive && styles.subscriptionPaymentButton_active,
      )}
    >
      {SUBSCRIPTION_CYCLE_COPY[cycle].adjective}
      <span
        className={cn(
          styles.subscriptionPaymentCost,
          isActive && styles.subscriptionPaymentCost_active,
        )}
      >
        {formatPrice(price + vat, currency)} /{" "}
        {SUBSCRIPTION_CYCLE_COPY[cycle].noun}
      </span>
      {cycle === "annual" && (
        <span className={styles.subscriptionPaymentDiscount}>Save 20%</span>
      )}
      {priceVatInfo && isActive && (
        <span className={styles.subscriptionPaymentVatInfo}>
          {priceVatInfo}
        </span>
      )}
    </button>
  );
}

function ExistingSubscriptionPayment() {
  const {
    subscriptionName,
    activeSubscriptionCycle,
    monthlySubscription,
    annualSubscription,
    currency,
  } = useContext(CheckoutModalContext);

  const { price, vat } =
    activeSubscriptionCycle === "monthly"
      ? monthlySubscription
      : annualSubscription;

  let breakdown;
  if (vat) {
    breakdown = `(${formatPrice(price, currency)} + ${formatPrice(
      vat,
      currency,
    )} VAT)`;
  }

  return (
    <GenericPayment
      productName={subscriptionName}
      total={
        formatPrice(price + vat, currency) +
        " / " +
        SUBSCRIPTION_CYCLE_COPY[activeSubscriptionCycle].noun
      }
      breakdown={breakdown}
    />
  );
}

function CreditsPayment() {
  const {
    credits: { amount, price, vat },
    currency,
    creditsWordingPluralizer = pluralizer("credit"),
  } = useContext(CheckoutModalContext);

  let breakdown;
  if (vat) {
    breakdown = `(${formatPrice(price, currency)} + ${formatPrice(
      vat,
      currency,
    )} VAT)`;
  }

  return (
    <GenericPayment
      productName={creditsWordingPluralizer(amount, true)}
      total={formatPrice(price + vat, currency)}
      breakdown={breakdown}
    />
  );
}

function GenericPayment({
  productName,
  total,
  breakdown,
}: {
  productName: string;
  total: string;
  breakdown?: string;
}) {
  return (
    <div className={styles.genericPayment}>
      <div className={styles.genericPaymentProductName}>{productName}</div>
      <div className={styles.genericPaymentTotalAndBreakdown}>
        <div className={styles.genericPaymentTotal}>{total}</div>
        {breakdown && (
          <div className={styles.genericPaymentBreakdown}>{breakdown}</div>
        )}
      </div>
    </div>
  );
}

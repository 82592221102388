import { cn } from "@sys42/utils";

import { ButtonPrimary } from "@/design-system";

import {
  BILLING_CYCLE_MONTHLY,
  formatPriceWithBillingCycle,
} from "./../../helpers";

import styles from "./styles.module.css";

import imgPlanIconStep from "./plan-icon-stack.jpg";

interface ExplorePlansPromoProps {
  onClick: () => void;
  className?: string;
  startingAtPrice: number;
  currency: Currency;
}

export default function ExplorePlansPromo({
  onClick,
  className,
  startingAtPrice,
  currency,
}: ExplorePlansPromoProps) {
  return (
    <section className={cn("card", styles.exportPlansPromo, className)}>
      <img className={styles.image} src={imgPlanIconStep} alt="Our plans" />
      <div className={styles.content}>
        <div>
          <h1 className={styles.heading}>Unlock AI Insights & more features</h1>
          <p>
            Get AI Insights, automated test reports, AI notes & clips, and more.
            Flexible plans start from{" "}
            {formatPriceWithBillingCycle(
              startingAtPrice,
              currency,
              BILLING_CYCLE_MONTHLY,
            )}
            .
          </p>
        </div>
        <ButtonPrimary onClick={onClick}>Explore our plans</ButtonPrimary>
      </div>
    </section>
  );
}

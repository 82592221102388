import CheckoutModal from "@/containers/CheckoutModal";
import { noop } from "@/helpers";
import { useModal2 } from "@/hooks/useModal2";
import { useAppDispatch, useAppSelector } from "@/store";
import { CreateTestModal } from "../CreateTestModal";
import { ExplorePlansModal } from "../ExplorePlansModal";

export function GlobalModals() {
  const dispatch = useAppDispatch();
  const globalModal = useAppSelector((state) => state.globalModal.modal);
  const globalModalOptions = useAppSelector(
    (state) => state.globalModal.modalOptions,
  );

  const { showModal: showCheckoutModal, modal: checkoutModal } = useModal2(
    // TODO: Refactor Checkout modal to use context instead of clone element
    /** @ts-ignore-next-line */
    <CheckoutModal />,
  );

  function handleCloseGlobalModal() {
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
  }

  function handleClickChoosePro(quantity: number, billingCycle: BillingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      quantity,
      plan: "pro2",
      billingCycle,
      redirectToBillingAfterCheckout:
        globalModalOptions?.redirectToBillingAfterCheckout ?? false,
    });
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
    showCheckoutModal();
  }

  return (
    <>
      <ExplorePlansModal
        /* The explore plans modal should only be display when you are on the payg (free) plan */
        onClickChoosePayg={noop}
        onClickChoosePro={handleClickChoosePro}
        onClose={handleCloseGlobalModal}
        isActive={globalModal === "explorePlans"}
      />
      <CreateTestModal
        templateId={globalModalOptions?.templateId}
        onClose={() => dispatch({ type: "GLOBAL_MODAL_CLOSE" })}
        isActive={globalModal === "createTest"}
      />
      {checkoutModal}
    </>
  );
}

import { useState } from "react";
import { cn } from "@sys42/utils";

import { Button, ButtonPrimary, Tooltip } from "@/design-system";
import {
  getUpdateSubscriptionCase,
  UpdateSubscriptionCases,
  urlBookADemo,
} from "@/helpers-ts";
import { useSubscription } from "@/hooks/useSubscription";
import {
  advertedPoolSize,
  BILLING_CYCLE_ANNUAL,
  formatPrice,
  formatPriceWithBillingCycle,
  getFromYourPlanWording,
  getPlanName,
  pluralizer,
} from "../../helpers";
import { addUtmParams } from "../../helpers-ts";
import { PricingSlider } from "../PricingSlider";

import { FeatureListItems } from "./FeatureListItems";

import styles from "./styles.module.css";

import imgPlanEnterprise from "./enterprise-fs8.png";
import imgPlanFree from "./free-fs8.png";
import imgPlanLegacy from "./legacy-fs8.png";
import imgPlanPro from "./pro-fs8.png";

type PricingApi = {
  payg: number;
  pro_monthly: {
    quantity: number;
    price: number;
  }[];
  pro_annual: {
    quantity: number;
    price: number;
  }[];
};

export type ProPlanInfoBoxProps = {
  className?: string;
  pricing: PricingApi;
  currency?: string;
  billingCycle: BillingCycle;
  onClickChoosePro: (
    quantity: number,
    billingCycle: BillingCycle,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  currentSubscription: ReturnType<typeof useSubscription>;
} & React.HTMLAttributes<HTMLDivElement>;

export function ProPlanInfoBox(props: ProPlanInfoBoxProps) {
  const {
    className,
    pricing,
    currency,
    billingCycle,
    onClickChoosePro,
    currentSubscription,
  } = props;

  const [selectedQuantity, setSelectedQuantity] = useState(() => {
    if (currentSubscription) {
      const pricingItem = pricing.pro_monthly.find(
        (item) => item.quantity === currentSubscription.quantity,
      );
      if (pricingItem) {
        return pricingItem.quantity;
      }
    }
    return pricing.pro_monthly[1].quantity;
  });

  const pricingCurrentBillingCycle =
    billingCycle === BILLING_CYCLE_ANNUAL
      ? pricing.pro_annual
      : pricing.pro_monthly;

  const currentPrice =
    pricingCurrentBillingCycle.find(
      (item) => item.quantity === selectedQuantity,
    )?.price || 0;

  const currentPricePerMonth =
    billingCycle === BILLING_CYCLE_ANNUAL ? currentPrice / 12 : currentPrice;

  const title = "Pro";
  const planImage = {
    src: imgPlanPro,
    alt: "A spaceshuttle on turquoise background",
  };

  // Pro

  let updateSubscriptionCase: UpdateSubscriptionCases;
  if (currentSubscription === null) {
    updateSubscriptionCase = UpdateSubscriptionCases.EXOTIC;
  } else {
    updateSubscriptionCase = getUpdateSubscriptionCase(currentSubscription, {
      plan: "pro2",
      quantity: selectedQuantity,
      billingCycle,
    });
  }

  let buttonLabel;
  let isCurrentPlan = false;
  switch (updateSubscriptionCase) {
    case UpdateSubscriptionCases.NO_CHANGE:
      buttonLabel = "Your current plan";
      isCurrentPlan = true;
      break;
    case UpdateSubscriptionCases.SWITCH_TO_MONTHLY:
      buttonLabel = "Switch to monthly billing";
      break;
    case UpdateSubscriptionCases.SWITCH_TO_ANNUAL:
      buttonLabel = "Switch to yearly billing";
      break;
    case UpdateSubscriptionCases.UPGRADE:
      buttonLabel = "Upgrade";
      break;
    case UpdateSubscriptionCases.DOWNGRADE:
      buttonLabel = "Downgrade";
      break;
    default:
      buttonLabel =
        billingCycle === "annual" ? "Choose Pro yearly" : "Choose Pro";
      break;
  }

  function handleClickButtonChoosePlan(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    onClickChoosePro?.(selectedQuantity, billingCycle, event);
  }

  const isMostPopular = true;

  const currentSubscriptionPriceWithoutTax = currentSubscription
    ? currentSubscription.plan_price /
      (1 + currentSubscription.tax_percent / 100)
    : 0;

  return (
    <div className={cn(className, styles.planInfoBox)}>
      <div className={styles.planHeader}>
        {planImage && <img src={planImage.src} alt={planImage.alt} />}
        <div className={styles.planTitleWrapper}>
          <h2 className={styles.planTitle}>{title}</h2>
          {isMostPopular && (
            <strong className={styles.mostPopular}>Most popular</strong>
          )}
        </div>
      </div>
      <div>
        <div className={styles.planPrice}>
          <span className={styles.planPricePrice}>
            {formatPrice(currentPricePerMonth / 100, currency, true)}
          </span>
          <div className={styles.planPriceInfo}>
            {currency?.toLocaleUpperCase()} per month
            {billingCycle === "annual" && (
              <>
                <br />
                {formatPrice(currentPrice / 100, currency, true)} billed yearly
              </>
            )}
          </div>
        </div>
        {renderIncludedTesters()}
        {renderQuantitySelect()}
        <ButtonPrimary
          fullWidth
          className={styles.startFreeTrialButton}
          onClick={handleClickButtonChoosePlan}
          disabled={isCurrentPlan}
        >
          {buttonLabel}
        </ButtonPrimary>
      </div>

      <ul className={styles.featureList}>
        <FeatureListItems
          collaboratorCount={15}
          includedFeatures={[true, true, true, true, true, false]}
        />
      </ul>

      {currentSubscription &&
        isCurrentPlan &&
        currentSubscriptionPriceWithoutTax !== currentPrice && (
          <div className={styles.planInfoFooter}>
            <div className={styles.currentPrice}>
              <div className={styles.currentPrice}>
                Your currently pay{" "}
                {formatPriceWithBillingCycle(
                  currentSubscriptionPriceWithoutTax / 100,
                  currentSubscription?.plan_currency,
                  currentSubscription?.billing_cycle,
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );

  function renderQuantitySelect() {
    // Extract quantity options from pricing data
    const quantityOptions = pricingCurrentBillingCycle.map(
      (item) => item.quantity,
    );

    // Create a handler for quantity changes
    const handleQuantityChange = (quantity: number) => {
      setSelectedQuantity(quantity);
    };

    return (
      <div className={styles.planSelectQuantity}>
        <PricingSlider
          selectedQuantity={selectedQuantity}
          quantityOptions={quantityOptions}
          onQuantityChange={handleQuantityChange}
        />
      </div>
    );
  }

  function renderIncludedTesters() {
    if (billingCycle === "annual") {
      return (
        <div className={styles.includedTesters}>
          <strong>
            {pluralizer("Userbrain tester")(selectedQuantity * 12, true)}
          </strong>{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            <span className={styles.includedTestersPeriod}>
              included per year{" "}
            </span>
            <Tooltip
              content={
                <>
                  You get {selectedQuantity * 12}&nbsp;testers each year from
                  our {advertedPoolSize}&nbsp;pool of qualified testers. All
                  testers are available upfront. Unused testers roll over until
                  you cancel.
                </>
              }
            />
          </span>
        </div>
      );
    } else {
      return (
        <div className={styles.includedTesters}>
          <strong>
            {pluralizer("Userbrain tester")(selectedQuantity, true)}
          </strong>{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            <span className={styles.includedTestersPeriod}>
              included each month{" "}
            </span>
            <Tooltip
              content={
                <>
                  You get {selectedQuantity}&nbsp;testers each month from our{" "}
                  {advertedPoolSize}&nbsp;pool of qualified testers. Unused
                  testers roll over until you cancel.
                </>
              }
            />
          </span>
        </div>
      );
    }
  }
}

export type FreePlanInfoBoxProps = {
  className?: string;
  currency?: string;
  onClickButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCurrentPlan: boolean;
  isDowngradeLocked: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function FreePlanInfoBox(props: FreePlanInfoBoxProps) {
  const {
    className,
    currency,
    onClickButton,
    isCurrentPlan,
    isDowngradeLocked,
  } = props;

  let buttonLabel = "Choose Free";

  if (isCurrentPlan) {
    buttonLabel = "Your current plan";
  } else {
    buttonLabel = "Downgrade to Free";
  }

  return (
    <div className={cn(className, styles.planInfoBox)}>
      <div className={styles.planHeader}>
        <img src={imgPlanFree} alt="A pixalated pointing cursor" />
        <div className={styles.planTitleWrapper}>
          <h2 className={cn(styles.planTitle)}>Free</h2>
        </div>
      </div>
      <div>
        <div className={styles.planPrice}>
          <span className={styles.planPricePrice}>
            {formatPrice(0, currency, true)}
          </span>
          <div className={styles.planPriceInfo}>per month</div>
        </div>
        <div className={styles.planAdditionalInfo}>
          Get Userbrain testers on demand{" "}
          <Tooltip
            content={
              <>
                Get testers from our {advertedPoolSize} pool of qualified
                testers on demand. No minimum order.
              </>
            }
          />
        </div>
        <ButtonPrimary
          fullWidth
          className={styles.startFreeTrialButton}
          onClick={onClickButton}
          disabled={isCurrentPlan || isDowngradeLocked}
        >
          {buttonLabel}
          {isDowngradeLocked && (
            <>
              {" "}
              <Tooltip
                content={
                  <>Only the account owner can downgrade to the Free plan.</>
                }
              />
            </>
          )}
        </ButtonPrimary>
      </div>
      <ul className={styles.featureList}>
        <FeatureListItems
          collaboratorCount={5}
          includedFeatures={[true, true, false, false, false, false]}
        />
      </ul>
    </div>
  );
}

type OutdatedPlanInfoBoxProps = {
  className?: string;
  subscription: {
    plan: string;
    quantity: number;
    plan_price: number;
    plan_currency: string;
    billing_cycle: BillingCycle;
  };
};

export function OutdatedPlanInfoBox(props: OutdatedPlanInfoBoxProps) {
  const { className, subscription } = props;

  const title = getPlanName(subscription.plan, subscription.quantity);
  const planImage = { src: imgPlanLegacy, alt: "A typewriter with a paper" };

  return (
    <div
      className={cn(className, styles.planInfoBox, styles.planInfoBox_outdated)}
    >
      <div className={styles.planHeader}>
        {planImage && <img src={planImage.src} alt={planImage.alt} />}
        <div className={styles.planTitleWrapper}>
          <h2 className={cn(styles.planTitle, styles.planTitle_small)}>
            {title}
          </h2>
          <div>
            {formatPriceWithBillingCycle(
              subscription.plan_price / 100,
              subscription.plan_currency,
              subscription.billing_cycle,
            )}
          </div>
        </div>
      </div>

      <div className={styles.outdatedInfo}>
        <div>
          This plan is no longer available. In case you decide to downgrade to
          our Free plan, you won't be able to subscribe to it again.
        </div>
      </div>
    </div>
  );
}

type PayAsYouGo = {
  creditsToBuy: number;
  creditsToUse: number;
  sessionsToUse: number;
  hasUserInvoices: boolean;
  isFreeTrial: boolean;
};

export type PayAsYouGoInfoBoxProps = {
  className?: string;
  paygPrice: number;
  payAsYouGo: PayAsYouGo;
  currency: string;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
} & React.HTMLAttributes<HTMLDivElement>;

export function PayAsYouGoInfoBox(props: PayAsYouGoInfoBoxProps) {
  const { className, paygPrice, payAsYouGo, currency, onClickButton } = props;

  const planImage = {
    src: imgPlanFree,
    alt: "A pixalated pointing cursor",
  };

  return (
    <div className={cn(className, styles.planInfoBox)}>
      <div className={styles.planHeader}>
        {planImage && <img src={planImage.src} alt={planImage.alt} />}
        <div className={styles.planTitleWrapper}>
          <h2 className={cn(styles.planTitle)}>Pay as you&nbsp;go</h2>
        </div>
      </div>

      <div className={styles.planPrice}>
        <span className={styles.planPricePrice}>
          {formatPrice(
            paygPrice * (payAsYouGo?.creditsToBuy || 0),
            currency,
            true,
          )}
        </span>
        <div className={styles.planPriceInfo}>
          {currency.toLocaleUpperCase()}
        </div>
      </div>

      <div className={styles.planAdditionalInfo}>
        <div className={styles.includedTesters}>
          <strong>
            {payAsYouGo?.creditsToBuy || 0}&nbsp;Userbrain&nbsp;
            {pluralizer("tester")(payAsYouGo?.creditsToBuy || 0, false)}
          </strong>{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            <span className={styles.includedTestersPeriod}>
              &times; {formatPrice(paygPrice, currency, true)} per tester
            </span>{" "}
            <Tooltip
              content={
                <>
                  <div className={styles.paygSummaryRow}>
                    <div>Your order</div>
                    <div>
                      {payAsYouGo.sessionsToUse +
                        payAsYouGo.creditsToUse +
                        payAsYouGo.creditsToBuy}{" "}
                      tester
                      {payAsYouGo.sessionsToUse +
                        payAsYouGo.creditsToUse +
                        payAsYouGo.creditsToBuy >
                        1 && "s"}
                    </div>
                  </div>
                  {payAsYouGo.sessionsToUse > 0 && (
                    <div className={styles.paygSummaryRow}>
                      <div>
                        {getFromYourPlanWording(
                          payAsYouGo.hasUserInvoices,
                          payAsYouGo.isFreeTrial,
                        )}
                      </div>
                      <div>
                        {payAsYouGo.sessionsToUse} tester
                        {payAsYouGo.sessionsToUse > 1 && "s"}
                      </div>
                    </div>
                  )}
                  {payAsYouGo.creditsToUse > 0 && (
                    <div className={styles.paygSummaryRow}>
                      <div>From your credits</div>
                      <div>
                        {payAsYouGo.creditsToUse} tester
                        {payAsYouGo.creditsToUse > 1 && "s"}
                      </div>
                    </div>
                  )}
                  <div className={styles.paygSummaryRow}>
                    <div>
                      {payAsYouGo.creditsToBuy}{" "}
                      <span className={styles.paygSummaryRowPricePerTester}>
                        {" "}
                        × {formatPrice(paygPrice, currency)} per tester
                      </span>
                    </div>
                    <div>
                      {formatPrice(
                        paygPrice * payAsYouGo.creditsToBuy,
                        currency,
                      )}
                    </div>
                  </div>
                  <div
                    className={cn(
                      styles.paygSummaryRow,
                      styles.paygSummaryRow_total,
                    )}
                  >
                    <div>Total cost</div>
                    <div>
                      {formatPrice(
                        paygPrice * payAsYouGo.creditsToBuy,
                        currency,
                      )}
                    </div>
                  </div>
                </>
              }
            />
          </span>
        </div>
      </div>
      <Button
        fullWidth
        className={styles.startFreeTrialButton}
        onClick={onClickButton}
      >
        {`Pay ${formatPrice(
          paygPrice * (payAsYouGo?.creditsToBuy || 0),
          currency,
        )}`}
      </Button>

      <ul className={styles.featureList}>
        <FeatureListItems
          collaboratorCount={5}
          includedFeatures={[true, true, false, false, false, false]}
        />
      </ul>
    </div>
  );
}

export type EnterprisePlanInfoBoxProps = {
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function EnterprisePlanInfoBox(props: EnterprisePlanInfoBoxProps) {
  const { className } = props;

  const planImage = {
    src: imgPlanEnterprise,
    alt: "A skyscraper on orange background",
  };

  return (
    <div className={cn(className, styles.planInfoBox)}>
      <div className={styles.planHeader}>
        {planImage && <img src={planImage.src} alt={planImage.alt} />}
        <div className={styles.planTitleWrapper}>
          <h2 className={cn(styles.planTitle)}>Enterprise</h2>
        </div>
      </div>
      <div>
        <div className={styles.planPrice}>
          <div
            className={cn(styles.planPricePrice, styles.planPricePrice_text)}
          >
            <span>Let's talk</span>
          </div>
        </div>
        <div className={styles.planAdditionalInfo}>
          Custom tester packages available
        </div>
        <Button
          fullWidth
          className={styles.startFreeTrialButton}
          target="_blank"
          href={addUtmParams(urlBookADemo, "dashboard", "pricing")}
        >
          Contact sales
        </Button>
      </div>
      <ul className={styles.featureList}>
        <FeatureListItems
          collaboratorCount={"Unlimited"}
          includedFeatures={[true, true, true, true, true, true]}
        />
      </ul>
    </div>
  );
}

import React, { useState } from "react";

import {
  Button,
  ButtonPrimary,
  ErrorMessage,
  FormField,
  InfoBox,
  RadioSelect,
  RadioSelectItem,
  Stack,
  TextArea,
} from "@/design-system";
import { shuffleArray } from "../../helpers";
import ButtonGroup from "../ButtonGroup";

import styles from "./styles.module.css";

const options = [
  "Not using it enough (or at all)",
  "Still evaluating",
  "Missing features we need",
  "Too expensive",
  "Switching to another product",
  "Shutting the business down",
];
shuffleArray(options);
options.push("Other");

const refTextarea = React.createRef();

export default function CancelSubscriptionForm(props) {
  const {
    cancelSubscriptionFetching,
    onCancelSubscription,
    errorMessage,
    onCancel,
    isOutdatedPlan,
    sessionsAvailable,
  } = props;

  const [confirmedLosingAvailableTesters, setConfirmedLosingAvailableTesters] =
    useState(false);
  const [reason, setReason] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState("");

  function handleChangeReason(e) {
    setReason(e.target.value);
    refTextarea.current.focus();
  }

  function handleChangeAdditionalDetails(e) {
    setAdditionalDetails(e.target.value);
  }

  function handleClickCancelSubscription() {
    onCancelSubscription(reason, additionalDetails);
  }

  function handleClickCancel() {
    onCancel();
  }

  function handleClickConfirmLosingAvailableTesters() {
    setConfirmedLosingAvailableTesters(true);
  }

  const outdatedPlanInfo = (
    <InfoBox>
      You’re currently on an outdated plan that’s no longer available. If you
      update your plan now, you won’t be able to switch back to your current
      plan.
      <br />
      <br />
      This action can’t be undone.
    </InfoBox>
  );

  if (!confirmedLosingAvailableTesters && sessionsAvailable > 0) {
    return (
      <div className={`${styles.form1}`}>
        <h1 className={styles.title}>Downgrade to the Free plan?</h1>
        <p>
          You’re about to switch to our Free plan. You won’t be charged again,
          and any unused testers will expire at the end of your current billing
          period.
        </p>
        <p>Consider using them before then to avoid losing them.</p>
        {isOutdatedPlan && outdatedPlanInfo}
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={handleClickCancel}>
            Keep my current plan
          </ButtonPrimary>
          <Button onClick={handleClickConfirmLosingAvailableTesters}>
            Continue downgrade
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  if (!confirmedLosingAvailableTesters) {
    return (
      <div className={`${styles.form1}`}>
        <h1 className={styles.title}>Downgrade to the Free plan?</h1>
        <p>
          You’re about to switch to our Free plan. You won’t be charged again.
        </p>
        {isOutdatedPlan && outdatedPlanInfo}
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={handleClickCancel}>
            Keep my current plan
          </ButtonPrimary>
          <Button onClick={handleClickConfirmLosingAvailableTesters}>
            Continue downgrade
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  return (
    <div className={`${styles.form2}`}>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <strong>
        Please let us know why you’re downgrading so we can improve. Thank you!
      </strong>
      <Stack>
        <RadioSelect
          onChange={handleChangeReason}
          value={reason}
          className={styles.options}
        >
          {options.map((option, index) => (
            <RadioSelectItem value={option} key={index}>
              {option}
            </RadioSelectItem>
          ))}
        </RadioSelect>
        <FormField
          className={styles.additionalDetails}
          label={"Mind adding some additional details?"}
        >
          <TextArea
            value={additionalDetails}
            onChange={handleChangeAdditionalDetails}
            innerRef={refTextarea}
            autoSize
            fullWidth
          />
        </FormField>
      </Stack>

      <ButtonGroup align={"right"} className={styles.buttonGroup}>
        <ButtonPrimary
          onClick={handleClickCancelSubscription}
          disabled={cancelSubscriptionFetching}
        >
          {cancelSubscriptionFetching ? "Processing..." : "Complete downgrade"}
        </ButtonPrimary>
        <Button onClick={handleClickCancel}>Cancel</Button>
      </ButtonGroup>
    </div>
  );
}

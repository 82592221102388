import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Spinner from "@/components/Spinner";
import { selectIsUserOwner } from "@/selectors/user";
import { useAppSelector } from "@/store";
import { FullscreenModal2 } from "../../components/Modal";
import {
  EnterprisePlanInfoBox,
  FreePlanInfoBox,
  OutdatedPlanInfoBox,
  ProPlanInfoBox,
} from "../../components/PlanInfoBox";
import { UserbrainPlans } from "../../components/UserbrainPlans";
import { BILLING_CYCLE_ANNUAL } from "../../helpers";
import { useCurrency } from "../../hooks/useCurrency";
import { usePricing } from "../../hooks/usePricing";
import { useSubscription } from "../../hooks/useSubscription";

import styles from "./styles.module.css";

export function EditSubscriptionModal(props) {
  const { isActive, onClose, onClickChoosePro, onClickChooseFree } = props;

  return (
    <FullscreenModal2
      isActive={isActive}
      onClose={onClose}
      content={
        <EditSubscriptionModalContent
          onClickChoosePro={onClickChoosePro}
          onClickChooseFree={onClickChooseFree}
        />
      }
    />
  );
}

function EditSubscriptionModalContent({ onClickChoosePro, onClickChooseFree }) {
  const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE_ANNUAL); // This is overridden by the active subscription

  function handleChangeBillingCycle(e) {
    setBillingCycle(e.target.value);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const pricing = usePricing();
  const currency = useCurrency();
  const subscription = useSubscription();
  const isOwner = useAppSelector(selectIsUserOwner);

  useEffect(() => {
    if (subscription) {
      setBillingCycle(subscription.billing_cycle);
    }
  }, [subscription]);

  useEffect(() => {
    if (location.hash === "#upgrade-yearly") {
      setBillingCycle(BILLING_CYCLE_ANNUAL);
      navigate(location.pathname + location.search, { replace: true });
    }
  }, [location, navigate]);

  function handleClickChoosePro(quantity, billingCycle) {
    onClickChoosePro(quantity, billingCycle);
  }

  const isLoading = pricing === null || currency === null;

  const isLegacyPlan = subscription?.plan !== "pro2";

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      <UserbrainPlans
        title={"Edit your subscription"}
        onChangeBillingCycle={handleChangeBillingCycle}
        billingCycle={billingCycle}
      >
        {isLegacyPlan && <OutdatedPlanInfoBox subscription={subscription} />}
        <FreePlanInfoBox
          currency={currency}
          onClickButton={onClickChooseFree}
          isCurrentPlan={subscription === false}
          isDowngradeLocked={!isOwner}
        />
        <ProPlanInfoBox
          currency={currency}
          billingCycle={billingCycle}
          onClickChoosePro={handleClickChoosePro}
          pricing={pricing}
          currentSubscription={subscription}
        />
        <EnterprisePlanInfoBox />
      </UserbrainPlans>
    </div>
  );
}

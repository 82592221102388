import React from "react";

import { Modal2 } from "@/components/Modal";
import { Modal2Props } from "@/components/Modal/DefaultModal";
import { noop } from "@/helpers";

import CheckoutModalContent from "./CheckoutModalContent";

export default function CheckoutModal({ isActive, onClose }: Modal2Props) {
  return (
    <Modal2
      isActive={isActive}
      onClose={noop}
      maxWidth={"30rem"}
      content={<CheckoutModalContent onClose={onClose} />}
    />
  );
}

import { useMemo } from "react";
import dayjs from "dayjs";

import { useAppSelector } from "@/store";

export type FreeTrialInfo = {
  isActive: boolean;
  expiresAt: Date | null;
  daysLeft: number | null;
  isExpired: boolean;
} | null;

export function useFreeTrial(): FreeTrialInfo {
  const freeTrial = useAppSelector(({ user }) =>
    user.user ? user.user.free_trial : null,
  );
  return useMemo(() => {
    if (freeTrial) {
      const expiresAt = freeTrial.expires_at
        ? new Date(freeTrial.expires_at)
        : null;
      const daysLeft = expiresAt
        ? dayjs(expiresAt)
            .subtract(10, "minutes") // To avoid showing 15 days left when server and client time are not perfectly in sync
            .diff(dayjs(), "day") + 1
        : null;
      return {
        isActive: freeTrial.active,
        expiresAt,
        daysLeft,
        isExpired: freeTrial.active === false && !!freeTrial.expires_at,
      };
    } else {
      return freeTrial;
    }
  }, [freeTrial]);
}

/**
 * Get free trial active
 * @returns {true|false|null} Returns wheter the free trial is active or not
 */
export function useIsFreeTrial(): boolean | null {
  return useAppSelector(({ user }) =>
    user.user ? user.user.free_trial.active : null,
  );
}

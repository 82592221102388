import { Reducer } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { Video } from "@/containers/Video/types";

import { ApiError } from "./types";

// VIDEOS

const VIDEOS_REQUEST = "VIDEOS_REQUEST";
// const VIDEOS_RE_REQUEST = "VIDEOS_RE_REQUEST";
const VIDEOS_FAILURE = "VIDEOS_FAILURE";
const VIDEOS_SUCCESS = "VIDEOS_SUCCESS";

export const VIDEOS_STAR_REQUEST = "VIDEOS_STAR_REQUEST";
export const VIDEOS_STAR_FAILURE = "VIDEOS_STAR_FAILURE";
export const VIDEOS_STAR_SUCCESS = "VIDEOS_STAR_SUCCESS";

//const VIDEOS_TESTS_REQUEST = "VIDEOS_TESTS_REQUEST";
//const VIDEOS_TESTS_FAILURE = "VIDEOS_TESTS_FAILURE";
const VIDEOS_TESTS_SUCCESS = "VIDEOS_TESTS_SUCCESS";

export const VIDEOS_RATE_REQUEST = "VIDEOS_RATE_REQUEST";
export const VIDEOS_RATE_FAILURE = "VIDEOS_RATE_FAILURE";
export const VIDEOS_RATE_SUCCESS = "VIDEOS_RATE_SUCCESS";

export const VIDEOS_UNSTAR_REQUEST = "VIDEOS_UNSTAR_REQUEST";
export const VIDEOS_UNSTAR_FAILURE = "VIDEOS_UNSTAR_FAILURE";
export const VIDEOS_UNSTAR_SUCCESS = "VIDEOS_UNSTAR_SUCCESS";

// reducer with initial state
const initialStateVideos: VideosState = {
  fetching: false,
  fetchingStar: [], // contains the id of every video where the application is waiting for a star response
  fetchingUnstar: [], // contains the id of every video where the application is waiting for a unstar response
  videos: null,
  videosRequestAction: null,
  videosRefreshHash: null,
  videosFilterOptionsTests: [],
  videosFilterOptionsDevices: [],
  totalCount: null,
  totalCountVideos: null,
  totalCountNew: null,
  totalCountStarred: null,
  perPage: null,
  error: null,
};

type VideosState = {
  fetching: boolean;
  fetchingStar: number[];
  fetchingUnstar: number[];
  videos: Video[] | null;
  videosRequestAction: string | null;
  videosRefreshHash: string | null;
  videosFilterOptionsTests: string[];
  videosFilterOptionsDevices: string[];
  totalCount: number | null;
  totalCountVideos: number | null;
  totalCountNew: number | null;
  totalCountStarred: number | null;
  perPage: number | null;
  error: ApiError | null;
};

export const videosReducer: Reducer<VideosState> = function (
  state = initialStateVideos,
  action,
) {
  let index;

  // Remove the fetching star flag when a success or failure comes in
  let updatedFetchingStar = state.fetchingStar;
  if (
    action.type === VIDEOS_STAR_SUCCESS ||
    action.type === VIDEOS_STAR_FAILURE
  ) {
    index = state.fetchingStar.findIndex((id) => id === action.id);
    if (index !== -1) {
      updatedFetchingStar = update(state.fetchingStar, {
        $splice: [[index, 1]],
      });
    }
  }

  // Remove the fetching unstar flag when a success or failure comes in
  let updatedFetchingUnstar = state.fetchingUnstar;
  if (
    action.type === VIDEOS_UNSTAR_SUCCESS ||
    action.type === VIDEOS_UNSTAR_FAILURE
  ) {
    index = state.fetchingUnstar.findIndex((id) => id === action.id);
    if (index !== -1) {
      updatedFetchingUnstar = update(state.fetchingUnstar, {
        $splice: [[index, 1]],
      });
    }
  }

  switch (action.type) {
    case VIDEOS_REQUEST:
      if (action.silent === true) {
        return { ...state, fetching: true, error: null };
      }
      return {
        ...state,
        fetching: true,
        videos: null,
        error: null,
        videosRefreshHash: null,
        videosRequestAction: null,
      };
    case VIDEOS_SUCCESS:
      if (!action.videos) {
        return {
          ...state,
          fetching: false,
        };
      }
      const {
        videos,
        totalCount,
        perPage,
        totalCountVideos,
        totalCountNew,
        totalCountStarred,
        refreshHash,
        requestAction,
      } = action;
      return {
        ...state,
        fetching: false,
        videos,
        totalCount,
        perPage,
        totalCountVideos,
        totalCountNew,
        totalCountStarred,
        videosRefreshHash: refreshHash,
        videosRequestAction: requestAction,
      };
    case VIDEOS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //case VIDEOS_TESTS_REQUEST:
    case VIDEOS_TESTS_SUCCESS:
      return { ...state, videosFilterOptionsTests: action.tests };
    case VIDEOS_STAR_REQUEST:
      index = state.fetchingStar.findIndex((id) => id === action.id);
      if (index === -1) {
        return {
          ...state,
          fetchingStar: update(state.fetchingStar, { $push: [action.id] }),
        };
      } else {
        return state;
      }
    case VIDEOS_STAR_SUCCESS:
      index = state.videos?.findIndex((video) => video.id === action.id) ?? -1;
      if (index !== -1) {
        return {
          ...state,
          fetchingStar: updatedFetchingStar,
          videos: update(state.videos, { [index]: { starred: { $set: 1 } } }),
        };
      } else {
        return { ...state, fetchingStar: updatedFetchingStar };
      }
    case VIDEOS_STAR_FAILURE:
      return { ...state, fetchingStar: updatedFetchingStar };
    case VIDEOS_UNSTAR_REQUEST:
      index = state.fetchingUnstar.findIndex((id) => id === action.id);
      if (index === -1) {
        return {
          ...state,
          fetchingUnstar: update(state.fetchingUnstar, { $push: [action.id] }),
        };
      } else {
        return state;
      }
    case VIDEOS_UNSTAR_SUCCESS:
      index = state.videos?.findIndex((video) => video.id === action.id) ?? -1;
      if (index !== -1) {
        return {
          ...state,
          fetchingUnstar: updatedFetchingUnstar,
          videos: update(state.videos, { [index]: { starred: { $set: 0 } } }),
        };
      } else {
        return { ...state, fetchingUnstar: updatedFetchingUnstar };
      }
    case VIDEOS_UNSTAR_FAILURE:
      return { ...state, fetchingUnstar: updatedFetchingUnstar };
    default:
      return state;
  }
};

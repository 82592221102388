import { useMemo } from "react";

import { useAppSelector } from "@/store";

export interface EnhancedUserbrainSubscription
  extends Omit<SubscriptionApi, "current_period_start" | "current_period_end"> {
  contactSupportToEdit: boolean;
  current_period_start: Date;
  current_period_end: Date;
}

/**
 * Retrieves the subscription information of the user.
 *
 * If the subscription data is not yet loaded, returns null.
 * If the user does not have a subscription, returns false.
 * Otherwise, returns the enhanced subscription object which includes:
 * - The subscription details from the API.
 * - 'current_period_start' and 'current_period_end' as Date objects.
 * - A 'contactSupportToEdit' flag that is true for enterprise subscriptions or when the billing cycle is annual.
 *
 * @returns {EnhancedUserbrainSubscription | false | null} The enhanced subscription object,
 * or false if the user does not have a subscription, or null if the subscription data is not yet loaded.
 */
export function useSubscription():
  | EnhancedUserbrainSubscription
  | false
  | null {
  const subscription = useAppSelector((state) => {
    if (state.user.user) {
      return state.user.user.subscription ?? false;
    } else {
      return null;
    }
  });

  return useMemo(() => {
    if (subscription) {
      const contactSupportToEdit = subscription.plan === "enterprise";

      return {
        ...subscription,
        current_period_start: new Date(subscription.current_period_start),
        current_period_end: new Date(subscription.current_period_end),
        contactSupportToEdit,
      };
    }
    return subscription;
  }, [subscription]);
}

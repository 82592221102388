import { useState } from "react";

import { FullscreenModal2 } from "@/components/Modal";
import {
  EnterprisePlanInfoBox,
  FreePlanInfoBox,
  PayAsYouGoInfoBox,
  ProPlanInfoBox,
} from "@/components/PlanInfoBox";
import Spinner from "@/components/Spinner";
import { UserbrainPlans } from "@/components/UserbrainPlans";
import { BILLING_CYCLE_ANNUAL } from "@/helpers";
import { useSubscription } from "@/hooks/useSubscription";
import { selectIsUserOwner } from "@/selectors/user";
import { useAppSelector } from "@/store";
import { useCurrency } from "../../hooks/useCurrency";
import { usePricing } from "../../hooks/usePricing";

import styles from "./styles.module.css";

export type PayAsYouGo = {
  creditsToBuy: number;
  creditsToUse: number;
  sessionsToUse: number;
  hasUserInvoices: boolean;
  isFreeTrial: boolean;
};

type ExplorePlansModalProps = {
  isActive: boolean;
  onClose: () => void;
} & ExplorePlansModalContentProps;

type ExplorePlansModalContentProps = {
  payAsYouGo?: PayAsYouGo;
  onClickChoosePro: (quantity: number, billingCycle: BillingCycle) => void;
  onClickChoosePayg: () => void;
};

export function ExplorePlansModal({
  payAsYouGo,
  isActive,
  onClose,
  onClickChoosePro,
  onClickChoosePayg,
}: ExplorePlansModalProps) {
  return (
    <FullscreenModal2
      isActive={isActive}
      onClose={onClose}
      content={
        <ExplorePlansModalContent
          payAsYouGo={payAsYouGo}
          onClickChoosePro={onClickChoosePro}
          onClickChoosePayg={onClickChoosePayg}
        />
      }
    />
  );
}

function ExplorePlansModalContent(props: ExplorePlansModalContentProps) {
  const { payAsYouGo, onClickChoosePro, onClickChoosePayg } = props;

  const [billingCycle, setBillingCycle] =
    useState<BillingCycle>(BILLING_CYCLE_ANNUAL);

  function handleChangeBillingCycle(e: React.ChangeEvent<HTMLSelectElement>) {
    setBillingCycle(e.target.value as BillingCycle);
  }

  const pricing = usePricing();
  const currency = useCurrency();
  const subscription = useSubscription();
  const isOwner = useAppSelector(selectIsUserOwner);

  function handleClickChoosePayg() {
    onClickChoosePayg();
  }

  const isLoading = pricing === null || currency === null;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!isLoading && (
        <div className={styles.content}>
          <UserbrainPlans
            title={"Choose the right plan for you"}
            onChangeBillingCycle={handleChangeBillingCycle}
            billingCycle={billingCycle}
            /*infoBox={<>📢 We'll increase the prices for all Userbrain plans on October 11th. Save up to 20% by subscribing now. <ButtonLink href={'https://www.userbrain.com/blog/updates-to-userbrains-pricing'} target={'_blank'}>Learn more</ButtonLink></>}*/
          >
            {payAsYouGo && (
              <PayAsYouGoInfoBox
                paygPrice={pricing.payg / 100}
                currency={currency}
                onClickButton={handleClickChoosePayg}
                payAsYouGo={payAsYouGo}
              />
            )}
            {!payAsYouGo && (
              <FreePlanInfoBox
                isCurrentPlan={subscription === false}
                currency={currency}
                onClickButton={onClickChoosePayg}
                isDowngradeLocked={!isOwner}
              />
            )}

            <ProPlanInfoBox
              pricing={pricing}
              currency={currency}
              billingCycle={billingCycle}
              onClickChoosePro={onClickChoosePro}
              currentSubscription={subscription}
            />
            <EnterprisePlanInfoBox />
          </UserbrainPlans>
        </div>
      )}
    </>
  );
}

import dayjs from "dayjs";

import {
  BILLING_CYCLE_ANNUAL,
  BILLING_CYCLE_MONTHLY,
  formatDateWithoutTime,
  getPlanName,
  pluralizer,
} from "@/helpers";
import {
  getUpdateSubscriptionCase,
  UpdateSubscriptionCases,
} from "@/helpers-ts";
import {
  EnhancedUserbrainSubscription,
  useSubscription,
} from "@/hooks/useSubscription";

type CreateCheckoutTextsArgs = {
  netPriceCharged: number;
  vatCharged: number;
  netPriceChargedNext: number;
  vatChargedNext: number;
  formatPrice: (price: number) => string;
  quantity: number;
  checkoutPlan: "payg" | "pro2";
  currentSubscription: ReturnType<typeof useSubscription>;
  billingCycle: BillingCycle;
  additionalSessions: number;
  deliveryRequest: {
    testId: string;
    repeatType: string;
    videoCount: number;
    targeting: Record<string, any>;
  };
};

type CreateCheckoutTextsReturn = {
  thankYouHeading: string;
  thankYouText: string;
  thankYouSubline: string;
  modalHeadingStepCreditCard: string;
  additionalInfoCharge: React.ReactNode;
  confirmButtonLabel: string;
  skipLastStep: boolean;
  isContactSupportForChanges: boolean;
  nextChargeDate: Date | null;
  creditsWordingPluralizer?: (count: number, includeCount: boolean) => string;
};

export function createCheckoutTexts({
  netPriceCharged,
  vatCharged,
  netPriceChargedNext,
  vatChargedNext,
  formatPrice,
  quantity,
  checkoutPlan,
  currentSubscription,
  billingCycle,
  deliveryRequest,
  additionalSessions,
}: CreateCheckoutTextsArgs): CreateCheckoutTextsReturn {
  const totalPriceCharged = netPriceCharged + vatCharged;
  const totalPriceChargedNext = netPriceChargedNext + vatChargedNext;

  const priceChargedVatInfo =
    vatCharged > 0 ? (
      <>
        {" "}
        ({formatPrice(netPriceCharged)} + {formatPrice(vatCharged)}&nbsp;VAT)
      </>
    ) : (
      ""
    );

  const todayPlusOneBillingCycle = dayjs(new Date())
    .add(1, billingCycle === BILLING_CYCLE_MONTHLY ? "month" : "year")
    .toDate();

  let updateSubscriptionCase: UpdateSubscriptionCases;
  if (currentSubscription === null || checkoutPlan === "payg") {
    updateSubscriptionCase = UpdateSubscriptionCases.EXOTIC;
  } else {
    updateSubscriptionCase = getUpdateSubscriptionCase(currentSubscription, {
      plan: checkoutPlan,
      quantity,
      billingCycle,
    });
  }

  switch (updateSubscriptionCase) {
    case UpdateSubscriptionCases.NEW_SUBSCRIPTION: {
      const nextChargeDate = todayPlusOneBillingCycle;
      const isAnnual = billingCycle === BILLING_CYCLE_ANNUAL;
      return createReturnValue({
        modalHeadingStepCreditCard: `Subscribe to ${getPlanName(checkoutPlan)}`,
        additionalInfoCharge: isAnnual ? (
          <>
            You’re subscribing to the Pro plan with{" "}
            {pluralizer("tester")(quantity * 12, true)} per year.
            <br />
            <br />
            All {pluralizer("tester")(quantity * 12, true)} will be available
            immediately after purchase.
          </>
        ) : (
          <>
            You’re subscribing to the Pro plan with{" "}
            {pluralizer("tester")(quantity, true)} per month.
            <br />
            <br />
            {pluralizer("tester")(quantity, true)} will be available immediately
            after purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for signing up to ${getPlanName(checkoutPlan)}!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UpdateSubscriptionCases.SWITCH_TO_ANNUAL:
    case UpdateSubscriptionCases.UPGRADE_AND_SWITCH_TO_ANNUAL:
    case UpdateSubscriptionCases.DOWNGRADE_AND_SWITCH_TO_ANNUAL:
    case UpdateSubscriptionCases.UPDATE_AND_SWITCH_TO_ANNUAL: {
      const nextChargeDate = todayPlusOneBillingCycle;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update to ${getPlanName(checkoutPlan)} yearly`,
        additionalInfoCharge: (
          <>
            You’re switching to a yearly {getPlanName(checkoutPlan)} plan with{" "}
            {pluralizer("tester")(quantity * 12, true)}.
            <br />
            <br />
            All {pluralizer("tester")(quantity * 12, true)} will be available
            immediately after purchase.
            <br />
            <br />
            Your next yearly payment of {formatPrice(
              totalPriceChargedNext,
            )}{" "}
            will be charged on {formatDateWithoutTime(nextChargeDate)}.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for updating to ${getPlanName(
          checkoutPlan,
        )} yearly!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UpdateSubscriptionCases.UPDATE: {
      const nextChargeDate = todayPlusOneBillingCycle;
      // Info: Update with quantity increase is always same billing cycle
      const quantityPerPeriod =
        billingCycle === BILLING_CYCLE_ANNUAL ? quantity * 12 : quantity;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update to ${getPlanName(checkoutPlan)} plan`,
        additionalInfoCharge: (
          <>
            You’re switching from your current plan to Pro with{" "}
            {pluralizer("tester")(quantityPerPeriod, true)} per{" "}
            {billingCycle === BILLING_CYCLE_ANNUAL ? "year" : "month"}.
            <br />
            <br />
            {totalPriceCharged === 0 ? (
              "Thanks to a credit from your previous plan, no payment is due."
            ) : (
              <>
                A credit from your previous plan has been applied, so you'll
                only pay {formatPrice(totalPriceCharged)}
                {priceChargedVatInfo} now.
              </>
            )}
            <br />
            <br />
            Your next monthly payment of {formatPrice(
              totalPriceChargedNext,
            )}{" "}
            will be charged on {formatDateWithoutTime(nextChargeDate)}.
            {additionalSessions > 0 && (
              <>
                <br />
                <br />
                {pluralizer("additional tester")(additionalSessions, true)} will
                be available immediately after purchase.
              </>
            )}
          </>
        ),
        confirmButtonLabel:
          totalPriceCharged === 0
            ? "Confirm update"
            : `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading:
          totalPriceCharged === 0 ? "Update completed" : "Payment successful",
        thankYouText: `Thanks for updating to ${getPlanName(checkoutPlan)}!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UpdateSubscriptionCases.UPGRADE: {
      const nextChargeDate = todayPlusOneBillingCycle;
      const quantityPerPeriod =
        billingCycle === BILLING_CYCLE_ANNUAL ? quantity * 12 : quantity;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update your ${getPlanName(checkoutPlan)} plan`,
        additionalInfoCharge: (
          <>
            You’re upgrading to {pluralizer("tester")(quantityPerPeriod, true)}{" "}
            per {billingCycle === BILLING_CYCLE_ANNUAL ? "year" : "month"}.
            <br />
            <br />
            {additionalSessions > 0 && (
              <>
                {pluralizer("additional tester")(additionalSessions, true)} will
                be available immediately after purchase.
                <br />
                <br />
              </>
            )}
            {totalPriceCharged === 0 ? (
              "Thanks to a credit from your last invoice, no payment is due."
            ) : (
              <>
                A credit from your previous plan has been applied, so you'll
                only pay {formatPrice(totalPriceCharged)}
                {priceChargedVatInfo} now.
              </>
            )}
            <br />
            <br />
            Your next monthly payment of {formatPrice(
              totalPriceChargedNext,
            )}{" "}
            will be charged on {formatDateWithoutTime(nextChargeDate)}.
          </>
        ),
        confirmButtonLabel:
          totalPriceCharged === 0
            ? "Confirm upgrade"
            : `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading:
          totalPriceCharged === 0 ? "Upgrade completed" : "Payment successful",
        thankYouText: `Thanks for upgrading your ${getPlanName(checkoutPlan)} plan!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UpdateSubscriptionCases.DOWNGRADE: {
      const nextChargeDate = (
        currentSubscription as EnhancedUserbrainSubscription
      ).current_period_end;
      const currentQuantity = (
        currentSubscription as EnhancedUserbrainSubscription
      ).quantity;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update your ${getPlanName(checkoutPlan)} plan`,
        confirmButtonLabel: "Confirm Downgrade",
        additionalInfoCharge: (
          <>
            You’re downgrading from{" "}
            {pluralizer("tester")(currentQuantity, true)} to{" "}
            {pluralizer("tester")(quantity, true)} per{" "}
            {billingCycle === BILLING_CYCLE_ANNUAL ? "year" : "month"}.
            <br />
            <br />
            Your next{" "}
            {billingCycle === BILLING_CYCLE_ANNUAL ? "yearly" : "monthly"}{" "}
            payment of {formatPrice(totalPriceChargedNext)} will be charged on{" "}
            {formatDateWithoutTime(nextChargeDate)}.
          </>
        ),
        skipLastStep: true,
      });
    }
    case UpdateSubscriptionCases.NO_CHANGE:
    case UpdateSubscriptionCases.SWITCH_TO_MONTHLY:
    case UpdateSubscriptionCases.UPGRADE_AND_SWITCH_TO_MONTHLY:
    case UpdateSubscriptionCases.DOWNGRADE_AND_SWITCH_TO_MONTHLY:
    case UpdateSubscriptionCases.UPDATE_AND_SWITCH_TO_MONTHLY:
    case UpdateSubscriptionCases.EXOTIC:
    default: {
      if (checkoutPlan === "payg") {
        let modalHeadingStepCreditCard;
        let additionalInfoCharge;
        let creditsWordingPluralizer;
        if (deliveryRequest) {
          const testerCount = deliveryRequest.videoCount - quantity;
          modalHeadingStepCreditCard = `Get ${pluralizer("tester")(
            deliveryRequest.videoCount,
            true,
          )}`;
          if (testerCount > 0) {
            /** @ts-ignore-next-line */
            additionalInfoCharge = `${pluralizer("tester is", "testers are")(
              testerCount,
              true,
            )} already covered by your plan/credits.`;
          } else {
            additionalInfoCharge = "";
          }
          creditsWordingPluralizer = pluralizer("additional tester");
        } else {
          modalHeadingStepCreditCard = `Get ${pluralizer("credit")(
            quantity,
            true,
          )}`;
          additionalInfoCharge = "";
          creditsWordingPluralizer = undefined;
        }
        return createReturnValue({
          modalHeadingStepCreditCard,
          additionalInfoCharge,
          confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
          thankYouHeading: "Payment successful",
          thankYouText: "Thanks for your order!",
          thankYouSubline: "You will receive your invoice via email.",
          creditsWordingPluralizer,
        });
      } else {
        return createReturnValue({
          isContactSupportForChanges: true,
        });
      }
    }
  }
}

function createReturnValue(
  options: Partial<ReturnType<typeof createCheckoutTexts>>,
): ReturnType<typeof createCheckoutTexts> {
  const {
    thankYouHeading = "",
    thankYouText = "",
    thankYouSubline = "",
    modalHeadingStepCreditCard = "",
    additionalInfoCharge = "",
    confirmButtonLabel = "",
    skipLastStep = false,
    isContactSupportForChanges = false,
    nextChargeDate = null,
    creditsWordingPluralizer = undefined,
  } = options;
  return {
    thankYouHeading,
    thankYouText,
    thankYouSubline,
    modalHeadingStepCreditCard,
    additionalInfoCharge,
    confirmButtonLabel,
    skipLastStep,
    nextChargeDate,
    isContactSupportForChanges,
    creditsWordingPluralizer,
  };
}

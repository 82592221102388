// INVOICES

import { Reducer } from "@reduxjs/toolkit";

import { ApiError } from "./types";

export const INVOICES_REQUEST = "INVOICES_REQUEST";
export const INVOICES_FAILURE = "INVOICES_FAILURE";
export const INVOICES_SUCCESS = "INVOICES_SUCCESS";

type InvoicesState = {
  fetching: boolean;
  invoices: InvoiceApi[] | null;
  totalCount: number | null;
  perPage: number | null;
  error: ApiError | null;
};

type InvoiceApi = {
  date: string;
  name: string;
  amount: number;
  currency: string;
  url: string;
};

// reducer with initial state
const initialStateInvoices: InvoicesState = {
  fetching: false,
  invoices: null,
  totalCount: null,
  perPage: null,
  error: null,
};

export const invoicesReducer: Reducer<InvoicesState> = function (
  state = initialStateInvoices,
  action,
): InvoicesState {
  switch (action.type) {
    case INVOICES_REQUEST:
      return { ...state, fetching: true, error: null };
    case INVOICES_SUCCESS:
      const { invoices, totalCount, perPage } = action;
      return { ...state, fetching: false, invoices, totalCount, perPage };
    case INVOICES_FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
};

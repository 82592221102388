import { useCallback, useEffect, useState } from "react";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  useLocation,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";

import BillingInformationForm from "@/components/BillingInformationForm";
import ButtonGroup from "@/components/ButtonGroup";
import { Modal2Props } from "@/components/Modal/DefaultModal";
import ModalHeader from "@/components/ModalHeader";
import Spinner from "@/components/Spinner";
import { CheckoutModalRedesign } from "@/containers/CheckoutModalRedesign";
import { Button, ButtonPrimary, ErrorMessage } from "@/design-system";
import {
  BILLING_CYCLE_ANNUAL,
  formatPrice as formatPriceWithCurrency,
  getPlanName,
  usePrevious,
} from "@/helpers";
import { paymentCardFromBilling } from "@/helpers-ts";
import { usePricing } from "@/hooks/usePricing";
import { useSubscription } from "@/hooks/useSubscription";
import { useAppDispatch, useAppSelector } from "@/store";

import { createCheckoutTexts } from "./createCheckoutTexts";

import styles from "./styles.module.css";

const stepBillingInformation = 1;
const stepCreditCard = 2;
const stepThankYou = 3;

type BillingInformation = {
  firstName: string;
  lastName: string;
  organization: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  vatId: string;
  billingEmail: string;
  taxPercent: number;
};

type CheckoutModalContentProps = {
  onClose: Modal2Props["onClose"];
  showContinueOrder?: boolean;
  continueOrderLabel?: string;
  onContinueOrder?: () => void;
};

export default function CheckoutModalContent(props: CheckoutModalContentProps) {
  const { onClose, showContinueOrder, continueOrderLabel, onContinueOrder } =
    props;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const location = useLocation();
  const revalidator = useRevalidator();

  const pathname = location.pathname;

  const [step, setStep] = useState(stepBillingInformation);
  const prevStep = usePrevious(step);
  const [billingInformation, setBillingInformation] =
    useState<BillingInformation | null>(null);
  const [payWithNewCard, setPayWithNewCard] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // Thank you texts (This must be stored in state because it is displayed when the currentSubscription is already updated)
  const [thankYouHeading, setThankYouHeading] = useState("");
  const [thankYouText, setThankYouText] = useState("");
  const [thankYouSubline, setThankYouSubline] = useState("");
  const [skipLastStep, setSkipLastStep] = useState(false);

  // Card payment
  const [handleCardPaymentErrorMessage, setHandleCardPaymentErrorMessage] =
    useState<string | null>(null);

  // Redux
  const dispatch = useAppDispatch();
  const currency = useAppSelector((state) => state.user.user?.currency);
  const hasUserInvoices = useAppSelector(
    (state) => state.user.user?.has_invoices,
  );
  const pricing = usePricing();
  const netPricePerCredit = (pricing?.payg ?? 0) / 100;

  const quantity = useAppSelector((state) => state.checkout.quantity);
  const checkoutPlan = useAppSelector((state) => state.checkout.plan);

  const isSubscription = checkoutPlan !== "payg";
  const redirectToBillingAfterCheckout = useAppSelector(
    (state) => state.checkout.redirectToBillingAfterCheckout,
  );
  const billingCycle = useAppSelector((state) => state.checkout.billingCycle);
  const deliveryRequest = useAppSelector(
    (state) => state.checkout.deliveryRequest,
  );

  const billingInformationUpdateError = useAppSelector(
    (state) => state.billingInformation.updateError,
  );

  const loadedBillingInformationError = useAppSelector(
    (state) => state.billingInformation.error,
  );

  const updateFetching = useAppSelector(
    (state) => state.billingInformation.updateFetching,
  );
  const availableCountries = useAppSelector(
    (state) => state.billingInformation.availableCountries,
  );
  const loadedBillingInformation = useAppSelector(
    (state) => state.billingInformation.billingInformation,
  );
  const loadedBillingInformationComplete = useAppSelector(
    (state) => state.billingInformation.complete,
  );
  const prevLoadedBillingInformationComplete = usePrevious(
    loadedBillingInformationComplete,
  );
  const vatCountries = useAppSelector(
    (state) => state.billingInformation.vatCountries,
  );
  const taxPercent = useAppSelector(
    (state) => state.user.user?.tax_percent || 0,
  );
  const hasSavedCard = useAppSelector(
    (state) => !!state.user.user?.billing?.card_last_four,
  );
  const billing = useAppSelector((state) => state.user.user?.billing);
  const subscription_owner = useAppSelector(
    (state) => state.user.user?.subscription_owner,
  );
  const isAdmin = useAppSelector((state) => state.user.user?.role === "admin");
  const isOwner = useAppSelector(
    (state) =>
      state.user.user?.role === "owner" ||
      state.user.user?.role === "superadmin",
  );

  const currentSubscription = useSubscription();

  const orderTestsFetching = useAppSelector(
    (state) => state.orderTests.fetching,
  );
  const prevOrderTestsFetching = usePrevious(orderTestsFetching);
  const orderTestsSuccess = useAppSelector((state) => state.orderTests.success);
  const orderTestsError = useAppSelector((state) => state.orderTests.error);

  function getProPrice(quantity: number, billingCycle: BillingCycle) {
    if (!pricing) {
      return 0;
    }
    const pricingKey =
      billingCycle === BILLING_CYCLE_ANNUAL ? "pro_annual" : "pro_monthly";
    const price = pricing[pricingKey].find(
      (price) => price.quantity === quantity,
    );
    return price?.price ?? 0;
  }

  const vat = (price: number) => price * (taxPercent / 100);

  const creditsPrice = netPricePerCredit * quantity;
  const creditsVat = vat(creditsPrice);
  const monthlySubscriptionPrice = getProPrice(quantity, "monthly") / 100;
  const monthlySubscriptionVat = vat(monthlySubscriptionPrice);
  const annualSubscriptionPrice = getProPrice(quantity, "annual") / 100;
  const annualSubscriptionVat = vat(annualSubscriptionPrice);

  let netPriceCharged: number;
  let netPriceChargedNext: number;
  let additionalSessions: number;
  if (checkoutPlan === "payg") {
    netPriceCharged = creditsPrice;
    netPriceChargedNext = 0;
    additionalSessions = 0;
  } else if (
    currentSubscription &&
    currentSubscription.billing_cycle === billingCycle
  ) {
    const newPlanPrice =
      billingCycle === BILLING_CYCLE_ANNUAL
        ? annualSubscriptionPrice
        : monthlySubscriptionPrice;
    netPriceCharged = Math.max(
      0,
      newPlanPrice - currentSubscription.current_period_totals.amount / 100,
    );
    netPriceChargedNext = newPlanPrice;
    if (billingCycle === BILLING_CYCLE_ANNUAL) {
      additionalSessions = Math.max(
        0,
        quantity * 12 - currentSubscription.current_period_totals.sessions,
      );
    } else {
      additionalSessions = Math.max(
        0,
        quantity - currentSubscription.current_period_totals.sessions,
      );
    }
  } else {
    netPriceCharged =
      billingCycle === BILLING_CYCLE_ANNUAL
        ? annualSubscriptionPrice
        : monthlySubscriptionPrice;
    netPriceChargedNext = netPriceCharged;
    additionalSessions = quantity;
  }

  const vatCharged = vat(netPriceCharged);
  const vatChargedNext = vat(netPriceChargedNext);

  const useSavedCard = hasSavedCard && !payWithNewCard;

  const {
    modalHeadingStepCreditCard,
    confirmButtonLabel,
    additionalInfoCharge,
    isContactSupportForChanges,
    thankYouHeading: thankYouHeadingWithCurrentData,
    thankYouText: thankYouTextWithCurrentData,
    thankYouSubline: thankYouSublineWithCurrentData,
    skipLastStep: skipLastStepWithCurrentData,
    creditsWordingPluralizer,
  } = createCheckoutTexts({
    formatPrice,
    vatCharged,
    netPriceCharged,
    vatChargedNext,
    netPriceChargedNext,
    quantity,
    checkoutPlan,
    billingCycle,
    currentSubscription,
    deliveryRequest,
    additionalSessions,
  });

  // navigate to billing if step changes to 3 and redirectToBillingAfterCheckout is true
  useEffect(() => {
    if (
      prevStep !== stepThankYou &&
      step === stepThankYou &&
      redirectToBillingAfterCheckout
    ) {
      navigate("/account/billing");
    }
  }, [step, redirectToBillingAfterCheckout, navigate, prevStep]);

  useEffect(() => {
    dispatch({ type: "BILLING_INFORMATION_REQUEST" });
  }, [dispatch]);

  useEffect(() => {
    setBillingInformation(loadedBillingInformation);
    if (
      loadedBillingInformationComplete &&
      !prevLoadedBillingInformationComplete
    ) {
      setStep(stepCreditCard);
    }
  }, [
    loadedBillingInformation,
    loadedBillingInformationComplete,
    prevLoadedBillingInformationComplete,
  ]);

  // Show step success if order was success
  useEffect(() => {
    if (
      orderTestsSuccess === true &&
      prevOrderTestsFetching === true &&
      orderTestsFetching === false
    ) {
      // Fetching did stop and success is true
      if (deliveryRequest) {
        const testId = deliveryRequest.testId;

        // ! Gleicher Code wie im OrderTestsModal !

        if (pathname.match(new RegExp(`^/test/${testId}(/.*)?$`, "i"))) {
          // If we are on any of the test detail views/tabs reload the test
          // this is needed because test loader does not revalidate the test by default
          dispatch({ type: "TEST_RE_REQUEST" });
        }

        if (pathname.match(new RegExp(`/test/${testId}/testers/?$`, "i"))) {
          // If we are on the correct testers page jump to the unfiltered first page
          setSearchParams({}); // will cause loader to refetch
        } else {
          navigate(`/test/${testId}/testers`);
        }
      } else {
        // Needed to reload insights or whatever after checkout a plan
        revalidator.revalidate();
      }
      if (skipLastStep === true) {
        onClose?.();
      } else {
        setStep(stepThankYou);
      }
    }
  }, [
    orderTestsFetching,
    deliveryRequest,
    orderTestsSuccess,
    prevOrderTestsFetching,
    navigate,
    location,
    dispatch,
    pathname,
    onClose,
    skipLastStep,
    setSearchParams,
    revalidator,
  ]);

  // Send order
  // This is used in useEffect thus the use of useCallback to not be a new function on every render
  const commitOrder = useCallback(
    (paymentMethodId: string | null) => {
      dispatch({
        type: "ORDER_TESTS_REQUEST",
        quantity,
        plan: checkoutPlan,
        billingCycle,
        netPriceCharged,
        currency,
        deliveryRequest,
        paymentMethodId: paymentMethodId ?? undefined,
        isTermsAccepted,
      });
    },
    [
      quantity,
      checkoutPlan,
      billingCycle,
      netPriceCharged,
      currency,
      deliveryRequest,
      isTermsAccepted,
      dispatch,
    ],
  );

  // Setup new card
  const paymentSetupClientSecretFetching = useAppSelector(
    (state) => state.payment.setupClientSecretFetching,
  );
  const prevPaymentCardSetupClientSecretFetching = usePrevious(
    paymentSetupClientSecretFetching,
  );
  const paymentSetupClientSecretError = useAppSelector(
    (state) => state.payment.setupClientSecretError,
  );
  const paymentSetupClientSecret = useAppSelector(
    (state) => state.payment.setupClientSecret,
  );
  const [handleCardSetupFetching, setHandleCardSetupFetching] = useState(false);
  const [handleCardSetupErrorMessage, setHandleCardSetupErrorMessage] =
    useState<string | null>(null);

  useEffect(() => {
    if (
      prevPaymentCardSetupClientSecretFetching === true &&
      paymentSetupClientSecretFetching === false &&
      stripe !== null
    ) {
      // fetching true -> false
      setHandleCardSetupFetching(true);
      stripe
        .confirmCardSetup(paymentSetupClientSecret, {
          payment_method: {
            card: elements!.getElement(CardNumberElement)!,
          },
        })
        .then((result) => {
          setHandleCardSetupFetching(false);
          if (result.error) {
            setHandleCardSetupErrorMessage(result.error?.message || "Error");
          } else {
            commitOrder(
              (result.setupIntent as { payment_method?: string })
                .payment_method ?? null,
            );
          }
        });
    }
  }, [
    paymentSetupClientSecret,
    paymentSetupClientSecretFetching,
    prevPaymentCardSetupClientSecretFetching,
    stripe,
    elements,
    commitOrder,
  ]);

  // Confirm Order Payment (eg. Verified by Visa)
  const orderTestsWaitingForConfirmation = useAppSelector(
    (state) => state.orderTests.waitingForConfirmation,
  );
  const orderTestsClientSecret = useAppSelector(
    (state) => state.orderTests.clientSecret,
  );
  useEffect(() => {
    if (orderTestsWaitingForConfirmation && stripe !== null) {
      stripe.confirmCardPayment(orderTestsClientSecret).then((result) => {
        if (result.error) {
          setHandleCardPaymentErrorMessage(result.error?.message || "Error");
          dispatch({ type: "ORDER_TESTS_CONFIRM_FAILED" });
        } else {
          dispatch({
            type: "ORDER_TESTS_CONFIRM",
            paymentIntentId: result.paymentIntent?.id,
            deliveryRequest,
          });
        }
      });
    }
  }, [
    orderTestsWaitingForConfirmation,
    orderTestsClientSecret,
    deliveryRequest,
    dispatch,
    stripe,
  ]);

  const setupFetching =
    paymentSetupClientSecretFetching || handleCardSetupFetching;
  const processingPayment =
    setupFetching || orderTestsFetching || orderTestsWaitingForConfirmation;

  function formatPrice(price: number) {
    return formatPriceWithCurrency(price, currency);
  }

  function handleChangeBillingInformation(
    billingInformation: BillingInformation,
  ) {
    setBillingInformation(billingInformation);
  }

  function handleClickEditPaymentCard() {
    setPayWithNewCard(true);
  }

  function handleClickContinueToCreditCard() {
    const {
      firstName = "",
      lastName = "",
      organization = "",
      street = "",
      postcode = "",
      city = "",
      country = "",
      vatId = "",
    } = billingInformation ?? {};
    dispatch({
      type: "BILLING_INFORMATION_UPDATE_REQUEST",
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    });
  }

  function handleChangeBillingCycle(billingCycle: BillingCycle) {
    dispatch({
      type: "CHECKOUT_SET_BILLING_CYCLE",
      billingCycle,
    });
  }

  function handleClickConfirmPurchase() {
    // Set the thank you texts in case it works
    setThankYouHeading(thankYouHeadingWithCurrentData);
    setThankYouText(thankYouTextWithCurrentData);
    setThankYouSubline(thankYouSublineWithCurrentData);
    setSkipLastStep(skipLastStepWithCurrentData);

    if (useSavedCard) {
      commitOrder(null);
    } else {
      dispatch({ type: "PAYMENT_SETUP_INTENT_REQUEST" });
    }
  }

  // Show spinner if not everything is ready
  if (
    (!billingInformation && !loadedBillingInformationError) ||
    !availableCountries
  ) {
    return <Spinner />;
  }

  if (step === stepBillingInformation) {
    if (isAdmin) {
      // Only the owner can add payment details
      return (
        <>
          <ModalHeader heading={"Billing address incomplete"} />
          <div className={styles.modalContent}>
            <p>The billing address for this account is not complete.</p>
            <p>Please contact the account owner to proceed.</p>
            <p>Account owner: {subscription_owner}</p>
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    if (loadedBillingInformationError) {
      return (
        <>
          <ModalHeader heading={"Payment"} />
          <div className={styles.modalContent}>
            {loadedBillingInformationError && (
              <ErrorMessage message={loadedBillingInformationError?.message} />
            )}
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    return (
      <>
        <ModalHeader heading={"Billing Address"} />
        <div className={styles.modalContent}>
          <BillingInformationForm
            autoFocus
            fullWidth
            errorMessage={
              billingInformationUpdateError?.message ||
              loadedBillingInformationError?.message
            }
            fieldValues={billingInformation}
            fieldFeedback={billingInformationUpdateError?.fieldFeedback}
            availableCountries={availableCountries}
            vatCountries={vatCountries}
            onChange={handleChangeBillingInformation}
          />
        </div>
        <div className={styles.footer}>
          <ButtonGroup align={"right"}>
            <ButtonPrimary
              onClick={handleClickContinueToCreditCard}
              disabled={updateFetching}
            >
              Continue to payment card
            </ButtonPrimary>
            <Button onClick={onClose}>Cancel</Button>
          </ButtonGroup>
        </div>
      </>
    );
  } else if (step === stepCreditCard) {
    if (!useSavedCard && !isOwner) {
      // Only the owner can add payment details
      return (
        <>
          <ModalHeader heading={"Missing payment card details"} />
          <div className={styles.modalContent}>
            <p>No payment card is stored for this account.</p>
            <p>Please contact the account owner to proceed.</p>
            <p>Account owner: {subscription_owner}</p>
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    if (isContactSupportForChanges) {
      // This is, under normal circumstances, not accessible via the interface
      return (
        <>
          <ModalHeader
            heading={"Contact support"}
            additionalInfoText={
              <span className={styles.additionalInfoCharge}>
                Please{" "}
                <a href="mailto:support@userbrain.com">contact support</a> to
                change your subscription.
              </span>
            }
          />
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    const paymentCardCheckout = paymentCardFromBilling(billing);

    const errors = [];

    if (paymentSetupClientSecretError) {
      errors.push(paymentSetupClientSecretError.message);
    }

    if (handleCardSetupErrorMessage) {
      errors.push(handleCardSetupErrorMessage);
    }

    if (handleCardPaymentErrorMessage) {
      errors.push(handleCardPaymentErrorMessage);
    }

    if (orderTestsError) {
      const { fieldFeedback, message } = orderTestsError;
      errors.push(message);
      if (fieldFeedback.test_delivery) {
        errors.push(fieldFeedback.test_delivery);
      }
    }

    const isDisplayErrorMessage = errors.length > 0;

    return (
      <CheckoutModalRedesign
        currency={currency}
        paymentType={
          isSubscription
            ? !!currentSubscription
              ? "existingSubscription"
              : "newSubscription"
            : "credits"
        }
        paymentDescription={additionalInfoCharge}
        // CREDITS
        credits={{
          amount: quantity,
          price: creditsPrice,
          vat: creditsVat,
        }}
        creditsWordingPluralizer={creditsWordingPluralizer}
        // SUBSCRIPTION
        subscriptionName={getPlanName(checkoutPlan)}
        activeSubscriptionCycle={billingCycle}
        monthlySubscription={{
          price: monthlySubscriptionPrice,
          vat: monthlySubscriptionVat,
        }}
        annualSubscription={{
          price: annualSubscriptionPrice,
          vat: annualSubscriptionVat,
        }}
        onClickSubscriptionCycle={handleChangeBillingCycle}
        // CARD
        card={useSavedCard ? paymentCardCheckout : null}
        isChangeCardAllowed={isOwner}
        onClickChangeCard={handleClickEditPaymentCard}
        // LEGAL
        onClickLegalAgree={() => setIsTermsAccepted(!isTermsAccepted)}
        isLegalAgree={isTermsAccepted}
        isLegalAgreementRequired={!hasUserInvoices}
        isDisplayLegalError={Boolean(
          orderTestsError?.fieldFeedback.terms_accepted,
        )} // XXX: Maybe this should be the text returned from the API
        // OTHER
        title={modalHeadingStepCreditCard}
        isConfirmButtonDisabled={processingPayment} // XXX: Maybe the buttons should be disabled when isLoading?
        isLoading={processingPayment}
        confirmButtonLabel={confirmButtonLabel}
        onClickConfirm={handleClickConfirmPurchase}
        onClickClose={() => onClose?.()}
        errorMessage={isDisplayErrorMessage ? errors.join(" ") : null}
      />
    );
  } else if (step === stepThankYou) {
    return (
      <>
        <ModalHeader heading={thankYouHeading} />
        <div className={styles.modalContent}>
          {thankYouText && <p>{thankYouText}</p>}
          {thankYouSubline && (
            <p className={styles.thankYouSubline}>{thankYouSubline}</p>
          )}
        </div>
        <div className={styles.footerThankYou}>
          <ButtonGroup align={"right"}>
            {showContinueOrder ? (
              <ButtonPrimary onClick={onContinueOrder}>
                {continueOrderLabel}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={() => onClose?.()}>Done</ButtonPrimary>
            )}
          </ButtonGroup>
        </div>
      </>
    );
  } else {
    return null;
  }
}

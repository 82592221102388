// CHECKOUT

import { Reducer } from "@reduxjs/toolkit";

export const CHECKOUT_INIT = "CHECKOUT_INIT";
export const CHECKOUT_SET_BILLING_CYCLE = "CHECKOUT_SET_BILLING_CYCLE";

type CheckoutState = {
  quantity: number;
  plan: "payg" | "pro2";
  billingCycle: BillingCycle;
  orderAttempt: boolean;
  iyopAttempt: boolean;
  deliveryRequest: any;
  redirectToBillingAfterCheckout: boolean;
};

const initialStateCheckout: CheckoutState = {
  quantity: 0,
  plan: "payg",
  billingCycle: "monthly",
  orderAttempt: false,
  iyopAttempt: false,
  deliveryRequest: null,
  redirectToBillingAfterCheckout: false,
};

export const checkoutReducer: Reducer<CheckoutState> = function (
  state = initialStateCheckout,
  action,
): CheckoutState {
  switch (action.type) {
    case CHECKOUT_INIT: {
      const {
        quantity,
        plan,
        billingCycle,
        orderAttempt,
        iyopAttempt,
        deliveryRequest,
        redirectToBillingAfterCheckout,
      } = action;
      return {
        ...state,
        quantity,
        plan,
        billingCycle,
        orderAttempt,
        iyopAttempt,
        redirectToBillingAfterCheckout: redirectToBillingAfterCheckout ?? false,
        deliveryRequest: deliveryRequest ?? null,
      };
    }
    case CHECKOUT_SET_BILLING_CYCLE: {
      return {
        ...state,
        billingCycle: action.billingCycle,
      };
    }
    default:
      return state;
  }
};

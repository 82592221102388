import { Tooltip } from "@/design-system";

type FeatureListItemsProps = {
  collaboratorCount: string | number;
  includedFeatures: [
    true | false,
    true | false,
    true | false,
    true | false,
    true | false,
    true | false,
  ];
};

export function FeatureListItems(props: FeatureListItemsProps) {
  const { collaboratorCount, includedFeatures } = props;

  return (
    <>
      <li data-feature-included={includedFeatures[0]}>
        Test with your own users
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[0] && (
            <Tooltip
              content={
                "Get up to 100 test sessions with your own customers, users, or team members every month. Refills monthly."
              }
            />
          )}
        </span>
      </li>
      <li data-feature-included={includedFeatures[1]}>
        {collaboratorCount} collaborator seats
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[1] && (
            <Tooltip
              content={
                "Collaborators can create tests and order testers. They can also make notes, create clips, and share test results."
              }
            />
          )}
        </span>
      </li>
      <li data-feature-included={includedFeatures[2]}>
        AI notes & clips
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[2] && (
            <Tooltip
              content={
                "AI automatically creates notes & clips of your key findings to share them with your team and stakeholders."
              }
            />
          )}
        </span>
      </li>
      <li data-feature-included={includedFeatures[3]}>
        Automated test reports
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[3] && (
            <Tooltip
              content={
                "Share a comprehensive summary of your tasks, questions, and notes with your team in a flash."
              }
            />
          )}
        </span>
      </li>
      <li data-feature-included={includedFeatures[4]}>
        AI insights
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[4] && (
            <Tooltip
              content={
                "Get instant AI insights to reveal key results from your user tests, based on AI-enhanced transcripts and time-stamped annotations."
              }
            />
          )}
        </span>
      </li>
      <li data-feature-included={includedFeatures[5]}>
        Custom contracts & billing options
        <span style={{ whiteSpace: "nowrap" }}>
          {" "}
          {includedFeatures[5] && (
            <Tooltip
              content={
                "Includes invoiced billing, annual contracts, custom pricing, and support for legal and procurement requirements."
              }
            />
          )}
        </span>
      </li>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelSubscriptionForm from "../components/CancelSubscriptionForm";
import { Modal2 } from "../components/Modal";
import { usePrevious } from "../helpers";

import { useSessions } from "./useSessions";
import { useSubscription } from "./useSubscription";

export function useCancelSubscriptionModal() {
  const dispatch = useDispatch();

  // Cancel subscription
  const [cancelSubscriptionModalActive, setCancelSubscriptionModalActive] =
    useState(false);
  const subscription = useSubscription();

  const isOutdatedPlan = !subscription?.plan_up_to_date;
  const sessions = useSessions();

  function handleCancelSubscription(reason, additionalDetails) {
    dispatch({
      type: "CANCEL_SUBSCRIPTION_REQUEST",
      reason,
      additionalDetails,
    });
  }

  const { isFetching, isError, error } = useSelector(
    (state) => state.cancelSubscription,
  );

  const prevIsFetching = usePrevious(isFetching);

  useEffect(() => {
    if (isFetching === false && prevIsFetching === true && isError === false) {
      setCancelSubscriptionModalActive(false);
    }
  }, [isFetching, prevIsFetching, isError]);

  function handleCloseSubscriptionModal() {
    setCancelSubscriptionModalActive(false);
  }

  return {
    showCancelSubscriptionModal: () => setCancelSubscriptionModalActive(true),
    cancelSubscriptionModal: (
      <Modal2
        onClose={handleCloseSubscriptionModal}
        isActive={cancelSubscriptionModalActive}
        content={
          <CancelSubscriptionForm
            cancelSubscriptionFetching={isFetching}
            onCancelSubscription={handleCancelSubscription}
            onCancel={handleCloseSubscriptionModal}
            sessionsAvailable={sessions?.available}
            isOutdatedPlan={isOutdatedPlan}
            errorMessage={
              isError && (error.message || error.fieldFeedback?.reason)
            }
          />
        }
      />
    ),
  };
}

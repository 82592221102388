import React, { useCallback, useEffect, useRef } from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

interface PricingSliderProps {
  className?: string;
  selectedQuantity: number;
  quantityOptions: number[];
  onQuantityChange: (quantity: number) => void;
  ariaLabel?: string;
}

export function PricingSlider({
  className,
  selectedQuantity,
  quantityOptions,
  onQuantityChange,
  ariaLabel = "Number of testers",
}: PricingSliderProps) {
  const sliderRef = useRef<HTMLInputElement>(null);

  const updateSliderBackground = useCallback(() => {
    if (sliderRef.current) {
      const index = quantityOptions.indexOf(selectedQuantity);
      const percentage = (index / (quantityOptions.length - 1)) * 100;
      sliderRef.current.style.setProperty(
        "--slider-percentage",
        `${percentage}%`,
      );
    }
  }, [selectedQuantity, quantityOptions]);

  useEffect(() => {
    updateSliderBackground();
  }, [updateSliderBackground]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    onQuantityChange(quantityOptions[value]);
  };

  const currentIndex = quantityOptions.indexOf(selectedQuantity);

  return (
    <div className={cn(styles.quantitySelector, className)}>
      <label htmlFor="quantity-slider" className={styles.visuallyHidden}>
        {ariaLabel}
      </label>
      <input
        ref={sliderRef}
        id="quantity-slider"
        type="range"
        min="0"
        max={quantityOptions.length - 1}
        step="1"
        value={currentIndex}
        onChange={handleQuantityChange}
        className={styles.slider}
        aria-valuemin={quantityOptions[0]}
        aria-valuemax={quantityOptions[quantityOptions.length - 1]}
        aria-valuenow={selectedQuantity}
        aria-valuetext={`${selectedQuantity} testers`}
      />
      <div className={styles.ticks} aria-hidden="true">
        {quantityOptions.map((value, index) => (
          <div
            key={value}
            className={styles.tick}
            data-active={index <= currentIndex}
            data-current={index === currentIndex}
          />
        ))}
      </div>
    </div>
  );
}
